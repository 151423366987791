var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '@gbds/core/src/ts/core';
let Button = class Button extends HTMLElement {
    get isAppendCurrentQueryParams() {
        return this.hasAttribute('data-append-current-query-params');
    }
    get isAppendCurrentHash() {
        return this.hasAttribute('data-append-current-hash');
    }
    constructor() {
        super();
        this.buttons = null;
    }
    onInit() {
        this.buttons = this.querySelectorAll('button');
        if (this.dataLinkType === 'targetZone' && this.buttons) {
            this.buttons.forEach(button => {
                button.onclick = e => this.changeTargetZone(e);
            });
        }
        else {
            this.anchorEl = this.querySelector('a');
            if (this.isAppendCurrentQueryParams || this.isAppendCurrentHash) {
                this.appendToHref();
            }
        }
    }
    isAuthenticated(button) {
        var _a, _b;
        if (!button) {
            return false;
        }
        const hasAuthTargetZoneId = button === null || button === void 0 ? void 0 : button.hasAttribute('data-auth-target-zone-id');
        const hasAuthTargetZoneSectionId = button === null || button === void 0 ? void 0 : button.hasAttribute('data-auth-target-zone-section-id');
        if (!hasAuthTargetZoneId || !hasAuthTargetZoneSectionId) {
            return false;
        }
        const authState = (_b = (_a = window.gbpe) === null || _a === void 0 ? void 0 : _a.auth) === null || _b === void 0 ? void 0 : _b.currentAuthState;
        if (authState === undefined || authState === null || authState === -1) {
            return false;
        }
        return authState === 1;
    }
    getTargetZoneId(button) {
        if (this.isAuthenticated(button)) {
            return this.dataAuthTargetZoneId;
        }
        else {
            return this.dataTargetZoneId;
        }
    }
    getTargetZoneSectionId(button) {
        if (this.isAuthenticated(button)) {
            return this.dataAuthTargetZoneSectionId;
        }
        else {
            return this.dataTargetZoneSectionId;
        }
    }
    changeTargetZone(e) {
        e.preventDefault();
        e.stopPropagation();
        const targetButton = e.currentTarget;
        const eventOpts = {
            bubbles: true,
            cancelable: false,
            detail: {
                zoneID: this.getTargetZoneId(targetButton),
                targetZoneID: this.getTargetZoneSectionId(targetButton),
            },
        };
        try {
            const evt = new CustomEvent('targetZoneChange', eventOpts);
            window.dispatchEvent(evt);
        }
        catch (error) {
            console.error('Error creating targetZoneChange event:', error);
        }
    }
    appendToHref() {
        const currentPageQueryParams = window.location.search;
        if (currentPageQueryParams && this.anchorEl) {
            let newHref = this.anchorEl.getAttribute('href');
            let hash = '#';
            const indexOfHash = newHref.indexOf('#');
            if (this.isAppendCurrentHash) {
                hash += window.location.hash.substr(1);
            }
            if (indexOfHash !== -1) {
                hash += newHref.substr(indexOfHash + 1);
                newHref = newHref.slice(0, indexOfHash);
            }
            if (this.isAppendCurrentQueryParams) {
                newHref += newHref.indexOf('?') === -1 ? '?' : '&';
                newHref += window.location.search.replace(/^\?/, '');
            }
            if (hash !== '#') {
                newHref += hash;
            }
            this.anchorEl.href = newHref;
        }
    }
};
__decorate([
    prop()
], Button.prototype, "dataLinkType", void 0);
__decorate([
    prop()
], Button.prototype, "dataTargetZoneId", void 0);
__decorate([
    prop()
], Button.prototype, "dataTargetZoneSectionId", void 0);
__decorate([
    prop()
], Button.prototype, "dataAuthTargetZoneId", void 0);
__decorate([
    prop()
], Button.prototype, "dataAuthTargetZoneSectionId", void 0);
Button = __decorate([
    component('gb-button')
], Button);
export { Button };
