export var ScriptDataAttributes;
(function (ScriptDataAttributes) {
    ScriptDataAttributes["required"] = "data-auth-required";
    ScriptDataAttributes["successTarget"] = "data-auth-success-target";
    ScriptDataAttributes["failTarget"] = "data-auth-fail-target";
    ScriptDataAttributes["appClientID"] = "data-auth-client-id";
    ScriptDataAttributes["waypointTarget"] = "data-auth-waypoint-target";
    ScriptDataAttributes["missingInfo"] = "data-missinginfo-required";
    ScriptDataAttributes["logoutURL"] = "data-post-logout-target";
    ScriptDataAttributes["sessionStorageKeyPrefix"] = "data-session-storage-clear-prefix";
    ScriptDataAttributes["baseApiUrlOverride"] = "data-base-api-url-override";
    ScriptDataAttributes["channelOverride"] = "data-auth-channel-override";
    ScriptDataAttributes["brandOverride"] = "data-auth-login-brand-override";
    ScriptDataAttributes["localeOverride"] = "data-auth-locale-override";
    ScriptDataAttributes["loginHint"] = "data-auth-login-hint";
    ScriptDataAttributes["mockEnabled"] = "data-mock-enabled";
    ScriptDataAttributes["policy"] = "data-auth-policy";
    ScriptDataAttributes["tenant"] = "data-auth-tenant";
    ScriptDataAttributes["disclosureCloseButtonLabel"] = "data-disclosure-close-button-label";
    ScriptDataAttributes["useDataAttributes"] = "data-use-data-attributes";
    ScriptDataAttributes["languageDataAttribute"] = "data-language";
    ScriptDataAttributes["countryDataAttribute"] = "data-country";
    ScriptDataAttributes["environmentDataAttribute"] = "data-environment";
    ScriptDataAttributes["rtlDataAttribute"] = "data-rtl";
})(ScriptDataAttributes || (ScriptDataAttributes = {}));
