export function initImgZoom($this) {
    const ZoomInBtn = $this.querySelector('.gb-zoom-in');
    const ZoomOutBtn = $this.querySelector('.gb-zoom-out');
    const fullscreenBtn = $this.querySelector('.gb-zoom-fullscreen');
    const wrapper = $this.querySelector('.gb-primary-wrapper');
    const imgContainer = $this.querySelector('.gb-wrapper-img-container');
    const universalImgContainer = $this.querySelector('.universal-image-wrapper-container');
    const btnCrlContainer = $this.querySelector('.gb-zoom-btn-container');
    const maxZoom = 4;
    const minZoom = 1;
    const maxDelay = 300;
    const zoomIncrement = 0.25;
    let offsetX = 0, offsetY = 0;
    let currentXPos = 0, currentYPos = 0;
    let currentZoom = minZoom;
    let lastTapTime = 0;
    let imgMoving = false;
    let isMultiTouch = false;
    let initialDist;
    let initialScale = 1;
    let fullscreenMode = false;
    let timeout;
    const fullScreenToggle = () => {
        if (wrapper.requestFullscreen && !fullscreenMode) {
            wrapper.requestFullscreen();
        }
        else if (fullscreenMode) {
            document.exitFullscreen();
        }
    };
    const zoomIn = () => {
        currentZoom = currentZoom >= maxZoom ? currentZoom : (currentZoom += zoomIncrement);
        if (currentZoom > minZoom) {
            ZoomOutBtn.classList.remove('gb-disabled');
        }
        if (currentZoom === maxZoom) {
            ZoomInBtn.classList.add('gb-disabled');
        }
        else {
            ZoomInBtn.classList.remove('gb-disabled');
        }
        return currentZoom;
    };
    const zoomOut = () => {
        currentZoom = currentZoom <= minZoom ? currentZoom : (currentZoom -= zoomIncrement);
        if (currentZoom === minZoom) {
            ZoomOutBtn.classList.add('gb-disabled');
        }
        else {
            ZoomOutBtn.classList.remove('gb-disabled');
        }
        if (currentZoom < maxZoom) {
            ZoomInBtn.classList.remove('gb-disabled');
        }
        return currentZoom;
    };
    imgContainer.addEventListener('mousedown', startDrag);
    imgContainer.addEventListener('mouseup', stopDrag);
    ZoomInBtn.addEventListener('click', ZoomInImg);
    ZoomOutBtn.addEventListener('click', zoomOutImg);
    fullscreenBtn.addEventListener('click', fullScreenToggle);
    imgContainer.addEventListener('dblclick', doubleClickImg);
    imgContainer.addEventListener('touchstart', touchStartImg);
    imgContainer.addEventListener('touchmove', touchMoveImg);
    imgContainer.addEventListener('touchend', touchEndImg);
    document.addEventListener('fullscreenchange', onFullScreenChange);
    function resetImg() {
        currentZoom = minZoom;
        universalImgContainer.style.transform = `scale(${minZoom}) translate(0px, 0px)`;
    }
    function onFullScreenChange() {
        fullscreenMode = fullscreenMode ? false : true;
    }
    function touchEndImg() {
        imgMoving = false;
        isMultiTouch = false;
    }
    function touchMoveImg(e) {
        e.preventDefault();
        if (currentZoom === 1 && !isMultiTouch) {
            imgMoving = false;
            return;
        }
        if (isMultiTouch) {
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            const currentDist = getDistance(touch1, touch2);
            const pinchOpen = currentDist > initialDist;
            const scale = (currentDist / initialDist) * initialScale;
            if (currentZoom === maxZoom && pinchOpen)
                return;
            if (scale <= maxZoom && scale > minZoom) {
                currentZoom = scale;
                universalImgContainer.style.transform = `scale(${currentZoom}) translate(${currentXPos}px, ${currentYPos}px)`;
            }
            else if (scale < minZoom) {
                currentZoom = minZoom;
                universalImgContainer.style.transform = `scale(${minZoom}) translate(${currentXPos}px, ${currentYPos}px)`;
            }
            return;
        }
        imgMoving = true;
        [...e.changedTouches].forEach(touch => {
            const x = touch.pageX - offsetX;
            const y = touch.pageY - offsetY;
            currentXPos = x;
            currentYPos = y;
            universalImgContainer.style.transform = `scale(${currentZoom}) translate(${x}px, ${y}px)`;
        });
    }
    function touchStartImg(e) {
        e.preventDefault();
        isMultiTouch = e.touches.length === 2;
        if (isMultiTouch) {
            isMultiTouch = true;
            const touch1 = e.touches[0];
            const touch2 = e.touches[1];
            initialDist = getDistance(touch1, touch2);
            initialScale = currentZoom;
            return;
        }
        if (!isMultiTouch) {
            const currentTime = new Date().getTime();
            const tapTime = currentTime - lastTapTime;
            if (tapTime < maxDelay && tapTime > 0) {
                clearTimeout(timeout);
                resetImg();
            }
            else {
                timeout = setTimeout(() => {
                    if (imgMoving)
                        return;
                    toggleImgCtrls();
                }, 400);
            }
            lastTapTime = currentTime;
        }
        if (currentZoom === 1)
            return;
        offsetX = e.targetTouches[0].pageX - universalImgContainer.offsetLeft;
        offsetY = e.targetTouches[0].pageY - universalImgContainer.offsetTop;
    }
    function doubleClickImg() {
        resetImg();
    }
    function zoomOutImg(e) {
        e.preventDefault();
        const currentZoomOut = zoomOut();
        if (currentZoomOut <= 1) {
            currentXPos = 0;
            currentYPos = 0;
        }
        if (currentZoom < minZoom) {
            currentZoom = minZoom;
        }
        universalImgContainer.style.transform = `scale(${currentZoom}) translate(${currentXPos}px, ${currentYPos}px)`;
    }
    function ZoomInImg(e) {
        e.preventDefault();
        const currentZoomIn = zoomIn();
        if (currentZoomIn > maxZoom) {
            currentZoom = maxZoom;
        }
        universalImgContainer.style.transform = `scale(${currentZoom}) translate(${currentXPos}px, ${currentYPos}px)`;
    }
    function getDistance(touch1, touch2) {
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }
    function toggleImgCtrls() {
        btnCrlContainer.classList.toggle('gb-hide');
    }
    function startDrag(e) {
        e.preventDefault();
        if (e.button !== 0 || currentZoom === minZoom)
            return;
        offsetX = e.clientX - universalImgContainer.offsetLeft;
        offsetY = e.clientY - universalImgContainer.offsetTop;
        imgContainer.addEventListener('mousemove', dragImage);
    }
    function stopDrag() {
        imgContainer.removeEventListener('mousemove', dragImage);
    }
    function dragImage(e) {
        const x = e.clientX - offsetX;
        const y = e.clientY - offsetY;
        currentXPos = x;
        currentYPos = y;
        universalImgContainer.style.transform = `scale(${currentZoom}) translate(${x}px, ${y}px)`;
    }
}
