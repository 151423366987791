var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '@gbds/core/src/ts/core';
import { debounce } from '@gbds/core/src/ts/utils/debounce';
import { isMediumViewport, isSmallViewport } from '@gbds/core/src/ts/utils/viewport';
import { GBModalService } from '../../../utils/modal-service';
import { watchAndLoadElementImages } from '../../../utils/lazy-loader-utils';
let GBTargetZone = class GBTargetZone extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        if (this.dataIsEdit !== 'true') {
            if (this.dataUseWith === 'scrubber') {
                this.listenForTime();
            }
            if (this.dataUseWith === 'button') {
                this.listenForTargetZoneChange();
            }
        }
        watchAndLoadElementImages(this);
    }
    isHostedInShadowDom() {
        return this.getRootNode() instanceof ShadowRoot;
    }
    listenForTargetZoneChange() {
        const TZC = this.querySelectorAll('.gb-zone-section');
        let Buttons = document.querySelectorAll('.gb-target-zone-button');
        if (this.isHostedInShadowDom) {
            const rootNode = this.getRootNode();
            Buttons = rootNode.querySelectorAll('.gb-target-zone-button');
        }
        let isSmViewport = isSmallViewport();
        let isMdViewport = isMediumViewport();
        if (TZC.length > 0) {
            let isModal = false;
            for (let i = 0; i < TZC.length; i++) {
                isModal = false;
                if ((isSmViewport && TZC[i].dataset.openOnSm === 'inModal') ||
                    (isMdViewport && TZC[i].dataset.openOnMd === 'inModal') ||
                    (!isSmViewport && !isMdViewport && TZC[i].dataset.openOnLg === 'inModal')) {
                    isModal = true;
                }
                if (!isModal) {
                    if (TZC[i].dataset.isDefaultZone === 'true') {
                        for (let j = 0; j < Buttons.length; j++) {
                            if (Buttons[j].dataset.targetZoneSectionId === TZC[i].dataset.zoneSectionId &&
                                Buttons[j].dataset.targetZoneId === this.dataZoneId) {
                                Buttons[j].classList.add('gb-target-zone-button-active');
                            }
                        }
                    }
                }
                else {
                    TZC[i].classList.add('gb-hide');
                }
                if (TZC[i].dataset.isDefaultZone === 'true' && !isModal) {
                    TZC[i].classList.remove('gb-hide');
                }
            }
        }
        else {
            if (this.dataIsDefaultZone) {
                this.classList.remove('gb-hide');
            }
            else {
                if (Buttons[0].dataset.targetZoneSectionId === this.dataZoneId) {
                    Buttons[0].classList.add('gb-target-zone-button-active');
                }
            }
        }
        window.addEventListener('resize', debounce(this.onBroadcastWindowResize.bind(this), 250));
        window.addEventListener('targetZoneChange', (e) => {
            const zoneID = e.detail.zoneID, targetZoneID = e.detail.targetZoneID;
            let isModal = false;
            isSmViewport = isSmallViewport();
            isMdViewport = isMediumViewport();
            if (this.dataZoneId === zoneID) {
                if (TZC.length > 0) {
                    for (let i = 0; i < TZC.length; i++) {
                        const TZC_UL = TZC[i].parentNode, TZC_UL_Parent = TZC_UL.parentNode, TZC_ZoneID = TZC_UL_Parent.dataset.zoneId;
                        if (zoneID === TZC_ZoneID && targetZoneID === TZC[i].dataset.zoneSectionId) {
                            isModal = false;
                            if ((isSmViewport && TZC[i].dataset.openOnSm === 'inModal') ||
                                (isMdViewport && TZC[i].dataset.openOnMd === 'inModal') ||
                                (!isSmViewport && !isMdViewport && TZC[i].dataset.openOnLg === 'inModal')) {
                                const mrkup = TZC[i].innerHTML;
                                isModal = true;
                                GBModalService.openModal({
                                    modalId: TZC[i].dataset.zoneSectionId,
                                    contentMarkup: mrkup,
                                    isFullscreen: true,
                                    isZoneTarget: true,
                                });
                            }
                            if (zoneID === TZC_ZoneID && TZC[i].classList.contains('gb-hide') && !isModal) {
                                TZC[i].classList.remove('gb-hide');
                            }
                        }
                        else {
                            if (zoneID === TZC_ZoneID) {
                                TZC[i].classList.add('gb-hide');
                            }
                        }
                    }
                }
                else {
                    if (e.detail.targetZoneID === this.dataZoneId) {
                        if (this.classList.contains('gb-hide')) {
                            this.classList.remove('gb-hide');
                        }
                    }
                    else {
                        if (!this.classList.contains('gb-hide')) {
                            this.classList.add('gb-hide');
                        }
                    }
                }
                for (let i = 0; i < Buttons.length; i++) {
                    if (Buttons[i].dataset.targetZoneSectionId === e.detail.targetZoneID &&
                        Buttons[i].dataset.targetZoneId === e.detail.zoneID) {
                        if (!Buttons[i].classList.contains('gb-target-zone-button-active')) {
                            Buttons[i].classList.add('gb-target-zone-button-active');
                        }
                    }
                    else if (Buttons[i].dataset.targetZoneId === e.detail.zoneID) {
                        if (Buttons[i].classList.contains('gb-target-zone-button-active')) {
                            Buttons[i].classList.remove('gb-target-zone-button-active');
                        }
                    }
                }
            }
        }, false);
    }
    onBroadcastWindowResize() {
        const TZC = this.querySelectorAll('.gb-zone-section');
        if (TZC.length > 0) {
            const isSmViewport = isSmallViewport();
            const isMdViewport = isMediumViewport();
            for (let i = 0; i < TZC.length; i++) {
                if ((isSmViewport && TZC[i].dataset.openOnSm === 'inModal') ||
                    (isMdViewport && TZC[i].dataset.openOnMd === 'inModal') ||
                    (!isSmViewport && !isMdViewport && TZC[i].dataset.openOnLg === 'inModal')) {
                    TZC[i].classList.add('gb-hide');
                }
            }
        }
    }
    listenForTime() {
        const TZC = this.querySelectorAll('.gb-zone-section');
        if (TZC.length > 0) {
            for (let i = 0; i < TZC.length; i++) {
                if (TZC[i].classList.contains('reveal-modal')) {
                    TZC[i].classList.remove('reveal-modal');
                }
                if (parseFloat(TZC[i].dataset.timeFrom) === parseFloat(TZC[i].dataset.timeTo) ||
                    parseFloat(TZC[i].dataset.timeFrom) == 0) {
                    TZC[i].classList.remove('gb-hide');
                }
            }
        }
        else {
            if (parseFloat(this.dataset.timeFrom) === parseFloat(this.dataset.timeTo) ||
                parseFloat(this.dataset.timeFrom) == 0) {
                this.classList.remove('gb-hide');
            }
        }
        window.addEventListener('timeChange', (e) => {
            const currentTime = e.detail.currentTime, zoneID = e.detail.zoneID;
            if (this.dataZoneId === zoneID) {
                if (TZC.length > 0) {
                    for (let i = 0; i < TZC.length; i++) {
                        const TZCmin = parseFloat(TZC[i].dataset.timeFrom), TZCmax = parseFloat(TZC[i].dataset.timeTo);
                        if (TZCmin !== TZCmax) {
                            if (currentTime >= TZCmin && currentTime <= TZCmax) {
                                if (TZC[i].classList.contains('gb-hide')) {
                                    TZC[i].classList.remove('gb-hide');
                                }
                            }
                            else {
                                if (!TZC[i].classList.contains('gb-hide')) {
                                    TZC[i].classList.add('gb-hide');
                                }
                            }
                        }
                    }
                }
                else {
                    const TZmin = this.dataTimeFrom, TZmax = this.dataTimeTo;
                    if (TZmin !== TZmax) {
                        if (currentTime >= TZmin && currentTime <= TZmax) {
                            if (this.classList.contains('gb-hide')) {
                                this.classList.remove('gb-hide');
                            }
                        }
                        else {
                            if (!this.classList.contains('gb-hide')) {
                                this.classList.add('gb-hide');
                            }
                        }
                    }
                }
            }
        }, false);
    }
};
__decorate([
    prop()
], GBTargetZone.prototype, "dataIsEdit", void 0);
__decorate([
    prop()
], GBTargetZone.prototype, "dataZoneId", void 0);
__decorate([
    prop()
], GBTargetZone.prototype, "dataUseWith", void 0);
__decorate([
    prop()
], GBTargetZone.prototype, "dataTimeFrom", void 0);
__decorate([
    prop()
], GBTargetZone.prototype, "dataTimeTo", void 0);
__decorate([
    prop()
], GBTargetZone.prototype, "dataIsDefaultZone", void 0);
GBTargetZone = __decorate([
    component('gb-target-zone')
], GBTargetZone);
export { GBTargetZone };
