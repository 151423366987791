var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { component, prop } from '@gbds/core/src/ts/core';
import { initImgZoom } from './img-zoom';
let Wrapper = class Wrapper extends HTMLElement {
    constructor() {
        super();
    }
    onInit() {
        var _a, _b;
        if (this.dataLinkType === 'targetZone') {
            this.buttonEl = this.querySelector('button');
            if (this.buttonEl) {
                const isAuthenticated = ((_b = (_a = window.gbpe) === null || _a === void 0 ? void 0 : _a.auth) === null || _b === void 0 ? void 0 : _b.currentAuthState) === 1;
                const targetZoneId = isAuthenticated
                    ? this.buttonEl.getAttribute('data-auth-target-zone-id') ||
                        this.buttonEl.getAttribute('data-target-zone-id')
                    : this.buttonEl.getAttribute('data-target-zone-id');
                const targetZoneSectionId = isAuthenticated
                    ? this.buttonEl.getAttribute('data-auth-target-zone-section-id') ||
                        this.buttonEl.getAttribute('data-target-zone-section-id')
                    : this.buttonEl.getAttribute('data-target-zone-section-id');
                this.buttonEl.onclick = e => this.changeTargetZone(e, targetZoneId, targetZoneSectionId);
            }
            else {
                console.log('Button element not found within component');
            }
        }
        if (this.dataLinkType === 'img-zoom') {
            initImgZoom(this);
        }
    }
    changeTargetZone(e, targetZoneId, targetZoneSectionId) {
        e.preventDefault();
        e.stopPropagation();
        const eventOpts = {
            bubbles: true,
            cancelable: false,
            detail: {
                zoneID: targetZoneId,
                targetZoneID: targetZoneSectionId,
            },
        };
        const evt = new CustomEvent('targetZoneChange', eventOpts);
        window.dispatchEvent(evt);
    }
};
__decorate([
    prop()
], Wrapper.prototype, "dataLinkType", void 0);
Wrapper = __decorate([
    component('gb-wrapper')
], Wrapper);
export { Wrapper };
