export const watchAndLoadElementImages = (element) => {
    let images, observer;
    const handleObservation = entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                images.forEach((imgEl) => {
                    const imgStyles = window.getComputedStyle(imgEl);
                    if (imgStyles.getPropertyValue('display') !== 'none') {
                        imgEl.removeAttribute('loading');
                    }
                });
                observer.unobserve(element);
            }
        });
    };
    if (element) {
        images = element.querySelectorAll('gb-responsive-image img, .gb-universal-image img, img.gb-universal-image');
        if (images.length) {
            observer = new IntersectionObserver(handleObservation.bind(this), {
                root: null,
                rootMargin: '0px',
                threshold: 0,
            });
            observer.observe(element);
        }
    }
};
